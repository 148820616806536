import React from 'react'
import Main from './main'


const LeadSection = () => {

  return (
      <Main
        title="Smart Solutions for Complex Analysis, Delivered Rapidly."
        text="Our AI-driven platform simplifies complex datasets with customizable criteria, providing precise, clear, and efficient insights for decision-makers who need rapid, comprehensive analysis."
        text2="We have a solution for your industry needs."
        text2Highlight={true}
      />
  )
}
export default LeadSection